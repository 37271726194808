 * {
   box-sizing: border-box;
 }
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.rc-slider-track {
  border: none !important;
  background: #5d00b4;
    background: -webkit-linear-gradient(
      -45deg,
      #5d00b4 0%,
      #5d00b4 11%,
      #5d00b4 11%,
      #5d00b4 30%,
      #e570e7 100%
    );
    background: -webkit-linear-gradient(
      315deg,
      #5d00b4 0%,
      #5d00b4 11%,
      #5d00b4 11%,
      #5d00b4 30%,
      #e570e7 100%
    );
    background: -o-linear-gradient(
      315deg,
      #5d00b4 0%,
      #5d00b4 11%,
      #5d00b4 11%,
      #5d00b4 30%,
      #e570e7 100%
    );
    background: linear-gradient(
      135deg,
      #5d00b4 0%,
      #5d00b4 11%,
      #5d00b4 11%,
      #5d00b4 30%,
      #e570e7 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5d00b4', endColorstr='#e570e7',GradientType=1 )
}

.rc-slider-handle {
  background: #5d00b4;
  background: -webkit-linear-gradient(
    -45deg,
    #5d00b4 0%,
    #5d00b4 11%,
    #5d00b4 11%,
    #5d00b4 30%,
    #e570e7 100%
  );
  background: -webkit-linear-gradient(
    315deg,
    #5d00b4 0%,
    #5d00b4 11%,
    #5d00b4 11%,
    #5d00b4 30%,
    #e570e7 100%
  );
  background: -o-linear-gradient(
    315deg,
    #5d00b4 0%,
    #5d00b4 11%,
    #5d00b4 11%,
    #5d00b4 30%,
    #e570e7 100%
  );
  background: linear-gradient(
    135deg,
    #5d00b4 0%,
    #5d00b4 11%,
    #5d00b4 11%,
    #5d00b4 30%,
    #e570e7 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5d00b4', endColorstr='#e570e7',GradientType=1 )
}

.swal2-container {
  z-index: 9999!important;
}