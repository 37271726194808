@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?micbb3');
  src:  url('fonts/icomoon.eot?micbb3#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?micbb3') format('truetype'),
    url('fonts/icomoon.woff?micbb3') format('woff'),
    url('fonts/icomoon.svg?micbb3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-flag:before {
  content: "\e923";
}
.icon-details:before {
  content: "\e909";
}
.icon-ignore:before {
  content: "\e914";
}
.icon-dollar:before {
  content: "\e922";
}
.icon-edollar:before {
  content: "\e921";
}
.icon-buildings:before {
  content: "\e91f";
}
.icon-house:before {
  content: "\e920";
}
.icon-tick1:before {
  content: "\e91e";
}
.icon-call:before {
  content: "\e918";
}
.icon-invoice_calender:before {
  content: "\e91d";
}
.icon-add:before {
  content: "\e917";
}
.icon-trash:before {
  content: "\e91c";
}
.icon-location:before {
  content: "\e919";
}
.icon-mail:before {
  content: "\e91a";
}
.icon-user:before {
  content: "\e91b";
}
.icon-online:before {
  content: "\e915";
}
.icon-offline:before {
  content: "\e916";
}
.icon-accept:before {
  content: "\e900";
}
.icon-calendar:before {
  content: "\e901";
}
.icon-cost:before {
  content: "\e902";
}
.icon-customer_listgps:before {
  content: "\e904";
}
.icon-date:before {
  content: "\e905";
}
.icon-employee_list:before {
  content: "\e906";
}
.icon-jobs:before {
  content: "\e90a";
}
.icon-overview:before {
  content: "\e90b";
}
.icon-settings:before {
  content: "\e90c";
}
.icon-time:before {
  content: "\e90d";
}
.icon-tracking:before {
  content: "\e90e";
}
.icon-address:before {
  content: "\e90f";
}
.icon-logout:before {
  content: "\e910";
}
.icon-message:before {
  content: "\e911";
}
.icon-phone:before {
  content: "\e912";
}
.icon-search:before {
  content: "\e913";
}
.icon-customer:before {
  content: "\e908";
}
.icon-px:before {
  content: "\e907";
}
.icon-jobs1:before {
  content: "\e903";
}
.icon-tick:before {
  content: "\ea10";
}
